<template>
  <div>
    <div class="operations">
      <div style="flex: 1"></div>
      <a-button @click="showForm = true">添加</a-button>
    </div>
    <giga-table :gData="dataSource" :gColumns="columns" :gInnerColumns="innerColumns" />
    <CatalogueForm :visible="showForm" :formData="formState" @formCancel="handleCancel" @formOk="handleOk" />
    <CatalogueContentForm
      :visible="addItem"
      :licensedBrands="licensedBrands"
      :products="products"
      :formData="formState"
      @formCancel="handleCancel"
      @formOk="handleOk($event, 'item')"
    />
  </div>
</template>
<script>
import { getGigaCatalogues, editGigaService, configureCatalogue, getProducts, getLicensedBrands } from "../http/api";
import GigaTable from "../components/GigaTable.vue";
import CatalogueForm from "../components/form/CatalogueForm";
import CatalogueContentForm from "../components/form/CatalogueContentForm";

export default {
  components: {
    GigaTable,
    CatalogueForm,
    CatalogueContentForm,
  },
  data() {
    return {
      licensedBrands: [],
      products: [],
      showForm: false,
      addItem: false,
      formState: {},
      dataSource: [],
      columns: [
        {
          title: "id",
          dataIndex: ["gigaService", "id"],
          key: "id",
          width: 80,
        },
        {
          title: "目录名",
          dataIndex: "gigaService",
          key: "name",
          customRender: (val) => {
            return <span v-html={(val.value && val.value.name) || ""}></span>;
          },
        },
        {
          title: "默认图标",
          dataIndex: "gigaService",
          key: "defaultIcon",
          customRender: (val) => {
            return (
              <img
                src={(val.value && val.value.icon && val.value.icon.default) || ""}
                style="width: 40px"
                onClick={() => this.showImg(val.value.icon.default)}
              />
            );
          },
        },
        {
          title: "选中图标",
          dataIndex: "gigaService",
          key: "selectedIcon",
          customRender: (val) => {
            return (
              <img
                src={(val.value && val.value.icon && val.value.icon.selected) || ""}
                style="width: 40px"
                onClick={() => this.showImg(val.value.icon.selected)}
              />
            );
          },
        },
        {
          title: "是否发布",
          dataIndex: "isPublished",
          key: "isPublished",
          customRender: (val) => <span v-html={val.value ? "已发布" : "未发布"} />,
        },
        {
          title: "排序",
          dataIndex: "displayOrder",
          key: "displayOrder",
          customRender: ({ record }) => {
            return (
              <a-input-number
                precision={0}
                v-model:value={record.displayOrder}
                style="width: 80px"
                onBlur={() => {
                  this.configureCatalogue({ gigaService: record.gigaService, displayOrder: record.displayOrder }, "order");
                }}
              />
            );
          },
        },
        {
          title: "操作",
          dataIndex: "operation",
          key: "operation",
          width: 200,
          customRender: (val) => {
            return (
              <div>
                <a-dropdown
                  trigger={["click"]}
                  overlay={
                    <a-menu>
                      <a-menu-item onClick={() => this.configureCatalogue(val.record)}>
                        <span>编辑</span>
                      </a-menu-item>
                      <a-menu-item onClick={() => this.configureCatalogue(val.record, "publish")}>
                        <span v-html={val.record.isPublished ? "取消发布" : "发布"}></span>
                      </a-menu-item>
                      <a-menu-item onClick={() => this.configureCatalogue(val.record, "del")}>
                        <span>删除</span>
                      </a-menu-item>
                    </a-menu>
                  }
                >
                  <a-button>操作</a-button>
                </a-dropdown>
                <a-button onClick={() => this.addContent(val.record)} style="margin-left:10px">
                  添加产品
                </a-button>
              </div>
            );
          },
        },
      ],
      // <a-button onClick={() => this.editGigaService(val.record.gigaService )}>编辑</a-button>
      innerColumns: [
        {
          title: "id",
          dataIndex: "id",
          key: "id",
        },
        {
          title: "名称",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "类型",
          dataIndex: "contentType",
          key: "contentType",
        },
        {
          title: "价格（¥）",
          dataIndex: "price",
          key: "price",
        },
        {
          title: "介绍",
          dataIndex: "description",
          key: "description",
          customRender: (val) => {
            return (
              <a-tooltip title={val.value}>
                <div style={{ maxWidth: "100%" }}>
                  {val.value.substring(0, 10)}
                  {val.value.length > 10 ? "..." : ""}
                </div>
              </a-tooltip>
            );
          },
        },
        {
          title: "封面图",
          dataIndex: "coverImage",
          key: "coverImage",
          customRender: (val) => {
            return (
              <img
                src={(val.value && val.value.url) || ""}
                style="width: 40px"
                onClick={() => this.showImg(val.value.url)}
              />
            );
          },
        },
        {
          title: "操作",
          dataIndex: "operation",
          key: "operation",
          customRender: (val) => {
            return (
              <div>
                <a-button
                  onClick={() => {
                    this.delProject(val);
                  }}
                  style="margin-left:10px"
                >
                  删除
                </a-button>
              </div>
            );
          },
        },
      ],
    };
  },
  created() {
    this.getGigaCatalogues();
    this.getData();
  },
  methods: {
    setImg(e, type) {
      let img = e[0];
      let formState = this.formState;
      formState[type] = img.url;
      this.formState = JSON.parse(JSON.stringify(formState));
    },
    handleOk(formState, isAddItem) {
      if (isAddItem) {
        console.log(formState);
        configureCatalogue({ variables: formState })
          .then((res) => {
            console.log(res);
            this.handleCancel();
            this.getGigaCatalogues();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        formState.isPublished = true;
        editGigaService({ variables: formState })
          .then((res) => {
            console.log(res);
            if (formState.id) {
              this.handleCancel();
              this.getGigaCatalogues();
              return;
            }

            this.editCatalogue({ gigaServiceId: res.data.editGigaService.id, items: [] });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    editCatalogue(formState) {
      configureCatalogue({ variables: formState })
        .then((res) => {
          console.log(res);
          this.handleCancel();
          this.getGigaCatalogues();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    configureCatalogue({ gigaService, isPublished, displayOrder }, type) {
      if (type == "del") {
        this.editCatalogue({ gigaServiceId: gigaService.id, isDeleted: true });
      } else if (type == "publish") {
        this.editCatalogue({ gigaServiceId: gigaService.id, isPublished: !isPublished });
      } else if (type == "order") {
        this.editCatalogue({ gigaServiceId: gigaService.id, displayOrder });
      } else {
        let record = gigaService;
        record.defaultIcon = record.icon.default;
        record.selectIcon = record.icon.selected;
        this.formState = record;
        this.showForm = true;
      }
    },
    addContent(record) {
      console.log(record);
      let data = {};
      let content = record.content;
      data["cid"] = record.id;
      data["brand"] = content.filter((e) => e.contentType == "brand").map((e) => e.id);
      data["product"] = content.filter((e) => e.contentType == "product").map((e) => e.id);
      data["items"] = content.map(({ id, contentType }) => ({ id, contentType }));
      data.gigaServiceId = record.gigaService.id;
      this.formState = data;
      this.addItem = true;
    },
    delProject(val) {
      console.log(val);
      let index = val.index;
      let record = val.record;
      let catalogue = this.dataSource.filter((e) => e.id == record.cid)[0];
      catalogue.content.splice(index, 1);
      console.log(catalogue);
      let data = {
        gigaServiceId: catalogue.gigaService.id,
        items: catalogue.content.map((e) => {
          return { id: e.id, contentType: e.contentType };
        }),
      };
      configureCatalogue({ variables: data })
        .then((res) => {
          console.log(res);
          this.handleCancel();
          this.getGigaCatalogues();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleCancel() {
      this.showForm = false;
      this.addItem = false;
      this.formState = {};
    },
    getGigaCatalogues() {
      getGigaCatalogues().then((res) => {
        if (res.errors) {
          this.$message.error(res.errors[0].message);
          return;
        }
        let dataSource = res.data.catalogues;
        dataSource.forEach((e) => {
          e.content.forEach((el) => (el.cid = e.id));
          e.innerData = e.content;
        });
        console.log("dataSource", dataSource);
        this.dataSource = dataSource;
      });
    },
    getData() {
      Promise.all([getProducts({ variables: { limit: 999 } }), getLicensedBrands()]).then((res) => {
        console.log(res);
        let products = res[0].data.products;
        let licensedBrands = res[1].data.licensedBrands;
        this.licensedBrands = licensedBrands;
        this.products = products;
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
