<template>
  <a-modal :visible="visible" title="车间服务" destroyOnClose :maskClosable="false"  @ok="handleOk" @cancel="handleCancel">
    <a-form
      ref="catalogueForm"
      :model="formState"
      name="catalogueForm"
      :validateOnRuleChange="false"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 19 }"
      autocomplete="off"
      scrollToFirstError
    >
      <a-form-item
        label="服务"
        name="name"
        :rules="[{ required: true, message: '输入服务名称' }]"
      >
        <a-input v-model:value="formState.name" name="name" />
      </a-form-item>
      <a-form-item
        label="默认图片"
        name="defaultIcon"
      >
        <GigaUpload v-model:value="formState.defaultIcon" name="defaultIcon" :imageUrl="formState.defaultIcon" @setImg="setImg($event, 'defaultIcon')"  />
      </a-form-item>
      <a-form-item
        label="选中图片"
        name="selectIcon"
      >
        <GigaUpload v-model:value="formState.selectIcon" name="selectIcon" :imageUrl="formState.selectIcon" @setImg="setImg($event, 'selectIcon')"  />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { ref, watch } from 'vue';
import GigaUpload from '../GigaUpload.vue'
export default {
  name: 'catalogueForm',
  components: {
    GigaUpload
  },
  props: ['visible', 'formData'],
  setup(props) {
    const formState = ref(props.formData)
    watch(() => props.formData, (newVal) => formState.value = newVal)
    return {
      formState,
      catalogueForm: ref(null),
    };
  },
  
  methods: {
    setImg(e, type) {
      let img = e[0]
      let formState = this.formState
      formState[type] = img.url
      this.formState = JSON.parse(JSON.stringify(formState))
    },
    handleOk() {
      this.catalogueForm.validateFields().then(res => {
        console.log(res)
        res.icon = {
          default: res.defaultIcon,
          selected: res.selectIcon
        }
        let formState = Object.assign(this.formState, res)
        this.$emit('formOk', formState)
      }).catch(err => {
        console.error(err)
        this.$message.error(err.errorFields[0].errors[0])
      })
    },
    handleCancel() {
      this.$emit('formCancel')
    },
  },

}
</script>